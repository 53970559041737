@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,900&display=swap");

@layer base {
  body {
    @apply font-[Raleway];
  }
  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

.slider {
  position: relative;
  width: 80px;
  height: 80px;
  transform-style: preserve-3d;
  animation: rotate 45s linear infinite;
}

@keyframes rotate {
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }

  100% {
    transform: perspective(1000px) rotateY(360deg);
  }
}

.slider span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: center;
  transform-style: preserve-3d;
  transform: rotateY(calc(var(--i) * 27.69deg)) translateZ(500px);
}

.slider span img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 2s;
  z-index: -1;
}

.slider span:hover img {
  transform: translateY(-30px) scale(1.2);
}

.project-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 350px;
}

.project-container:hover {
  background-image: linear-gradient(
    to right,
    rgba(156, 255, 252, 0.8),
    hsla(189, 100%, 60%, 0.8)
  ) !important;
}
